import React from 'react';
import { Row, Col } from '../../bootstrap';
import { Field } from '../field';

export default ({ data }) => {
    const {
        chargePayer,
        accountReference,
        rateableValue,
        personOrSoleTraderName,
        description,
        limitedCompanyNumber,
        charityNumber,
        chargePayerEmail,
        dateOfOccupation,
        dateOfVacation,
        effectiveDateOfRVChange,
        liabilityStartDate,
        liabilityEndDate,
        mixedHeriditamentIndicator,
        address,
        correspondenceAddress,
        forwardingAddr,
        sbrReliefIndicator,
        sbrDateReliefCommenced,
        charitableRelief,
        ruralRateRelief,
        ruralRateReliefPercentage,
        hardshipRelief,
        hardshipReliefPercentage,
        discretionaryRelief,
        discretionaryReliefRate,
        emptyPropertyReliefStartDate,
        emptyPropertyReliefEndDate
        } = data;
    return (
        <>
            <Row className="pb-3">
                <Col size={4}>
                    <Field label="Rate Payer" value={chargePayer} />
                </Col>
                <Col size={4}>
                    <Field label="Account Reference" value={accountReference} />
                </Col>
                <Col size={4}>
                    <Field label="Rateable Value" value={rateableValue} />
                </Col>
            </Row>
            <Row className="pb-3">
                <Col size={4}>
                    <Field label="Person or Sole Trader Name" value={personOrSoleTraderName} />
                </Col>
                <Col size={8}>
                    <Field label="Description" value={description} />
                </Col>
            </Row>
            <Row className="pb-3">
                <Col size={4}>
                    <Field label="Limited Company Number" value={limitedCompanyNumber} />
                </Col>
                <Col size={4}>
                    <Field label="Charity Number" value={charityNumber} />
                </Col>
                <Col size={4}>
                    <Field label="Rate Payer Email" value={chargePayerEmail} />
                </Col>
            </Row>
            <Row className="pb-3">
                <Col size={4}>
                    <Field label="Date of Occupation" value={dateOfOccupation} />
                </Col>
                <Col size={4}>
                    <Field label="Date of Vacation" value={dateOfVacation} />
                </Col>
                <Col size={4}>
                    <Field label="Effective Date Of R V Change" value={effectiveDateOfRVChange} />
                </Col>
            </Row>
            <Row className="pb-3">
                <Col size={4}>
                    <Field label="Liability Start Date" value={liabilityStartDate} />
                </Col>
                <Col size={4}>
                    <Field label="Liability End Date" value={liabilityEndDate} />
                </Col>
                <Col size={4}>
                    <Field label="Mixed Heriditament Indicator" value={mixedHeriditamentIndicator} />
                </Col>
            </Row>
            <Row className="pb-3">
                <Field label="Address" value={address} />
            </Row>
            <Row className="pb-3">
                <Field label="Correspondence Address" value={correspondenceAddress} />
            </Row>
            <Row className="pb-3">
                <Field label="Forwarding Address" value={forwardingAddr} />
            </Row>
            <Row className="pb-3">
                <Col size={4}>
                    <Field label="SBRR/SBBS Indicator" value={sbrReliefIndicator} />
                </Col>
                <Col size={4}>
                    <Field label="SBRR/SBBS Date Commenced" value={sbrDateReliefCommenced} />

                </Col>
                <Col size={4}>
                    <Field label="Charitable Relief" value={charitableRelief} />
                </Col>
            </Row>
            <Row className="pb-3">
                <Col size={4}>
                    <Field label="Rural Rate Relief" value={ruralRateRelief} />
                </Col>
                <Col size={8}>
                    <Field label="Rural Rate Relief Percentage" value={ruralRateReliefPercentage} />
                </Col>
            </Row>
            <Row className="pb-3">
                <Col size={4}>
                    <Field label="Hardship Relief" value={hardshipRelief} />

                </Col>
                <Col size={8}>
                    <Field label="Hardship Relief Percentage" value={hardshipReliefPercentage} />
                </Col>
            </Row>
            <Row className="pb-3">
                <Col size={4}>
                    <Field label="Discretionary Relief" value={discretionaryRelief} />
                </Col>
                <Col size={8}>
                    <Field label="Discretionary Relief Rate" value={discretionaryReliefRate} />
                </Col>
            </Row>
            <Row className="pb-3">
                <Col size={4}>
                    <Field label="Empty Property Relief Start Date" value={emptyPropertyReliefStartDate} />
                </Col>
                <Col size={8}>
                    <Field label="Empty Property Relief End Date" value={emptyPropertyReliefEndDate} />
                </Col>
            </Row>
        </>
    )
}
