import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import AddressFinder from '../../address-finder';
import SearchTypes from '../../searches-results/search-types';
import AddAlert from './add-alert';
import { selector } from '../../../redux-reducers/quick-search-reducer';
import './index.css';
import { BootstrapContainer, Row, Hr, Col } from '../../bootstrap';
import ResultsSummaries from './results-summaries';


const getResultDetail = ({ error, searchType, title, count, setDisplayResults, displayResults }) => {
    if (error) {
        return <button style={{fontSize:'14px'}} className={`b4-nav-link disabled`}>{error.Description}</button>;
    }
    const outline = displayResults === searchType;
    const disabled = count <= 0;

    return <button style={{fontSize:'14px'}} className={`b4-nav-link ${outline ? 'active' : ''} ${disabled ? 'disabled' : ''}`} onClick={() => setDisplayResults(searchType)}>{count} {title} results</button>;
}

const getCouncilTaxResultDetail = (data, setDisplayResults, displayResults) => {
    const error = data.councilTaxQueryError;
    const summaries = data.councilTaxNameSummaries;
    const count = data.councilTaxNameCount;

    return getResultDetail({
        error,
        searchType: SearchTypes.CouncilTaxName,
        summaries,
        title: 'Council Tax',
        count,
        setDisplayResults,
        displayResults
    });
}

const getBusinessRatesResultDetail = (data, setDisplayResults, displayResults) => {
    const error = data.businessRatesQueryError;
    const summaries = data.businessRatesSummaries;
    const count = data.businessRatesCount;

    return getResultDetail({
        error,
        searchType: SearchTypes.BusinessRates,
        summaries,
        title: 'Business Rates',
        count,
        setDisplayResults,
        displayResults
    });
}

const QuickSearchResults = () => {
    const [displayResults, setDisplayResults] = useState(SearchTypes.None);

    const quickSearchPostcodeData = useSelector(selector.selectPostcodeSearchModel);
    const quickSearchTelephoneData = useSelector(selector.selectTelephoneSearchModel);
    const quickSearchEmailData = useSelector(selector.selectEmailSearchModel);
    const querySearchQuery = useSelector(selector.selectQuery);
    const useQuickSearchAlerts = useSelector(selector.selectQuickSearchAlertsEnabled);

    const toggleDisplayResults = (value) => {
        if (displayResults === value) {
            setDisplayResults(SearchTypes.None);
        }
        else {
            setDisplayResults(value);
        }
    }

    if (quickSearchPostcodeData) {
        return <AddressFinder searchResult={quickSearchPostcodeData} query={querySearchQuery} />;
    }

    const alerts = useQuickSearchAlerts ? 
        <BootstrapContainer>
            <Row className="pt-4 justify-content-center">
                <Col size={6}>
                    <AddAlert searchType="Email" query={querySearchQuery} />
                </Col>
            </Row> 
        </BootstrapContainer>
        : null;

    if (quickSearchEmailData) {
        const councilTax = () => getCouncilTaxResultDetail(quickSearchEmailData, toggleDisplayResults, displayResults);
        const businessRates = () => getBusinessRatesResultDetail(quickSearchEmailData, toggleDisplayResults, displayResults);


        return (
            <>
                <BootstrapContainer className="quick-search-results">
                    <Row style={{ height: "40px" }}>
                        <ul className="b4-nav b4-nav-tabs w-100 d-flex justify-content-center">
                            <li className="b4-nav-item">
                               {councilTax()}
                            </li>
                            <li className="b4-nav-item">
                                {businessRates()}
                            </li>
                        </ul>
                    </Row>
                </BootstrapContainer>
                <ResultsSummaries resultsType={displayResults} data={quickSearchEmailData} />
                {alerts}
            </>
        );
    }

    if (quickSearchTelephoneData) {
        const councilTax = () => getCouncilTaxResultDetail(quickSearchTelephoneData, toggleDisplayResults, displayResults);

        return (
            <>
                <BootstrapContainer className="quick-search-results">
                    <Row style={{ height: "40px" }}>
                        <ul className="b4-nav b4-nav-tabs w-100 d-flex justify-content-center">
                            <li className="b4-nav-item">
                               {councilTax()}
                            </li>
                        </ul>
                    </Row>
                </BootstrapContainer>
                <ResultsSummaries resultsType={displayResults} data={quickSearchTelephoneData} />
                {alerts}
            </>
        );
    }

    return <div>Unexpected error occured</div>

}
export default QuickSearchResults;