import React, {useEffect, useState} from 'react';
import {BootstrapSpinner} from '../../bootstrap';
import { HashRouter as Router, Route, useLocation } from "react-router-dom";
import axios from 'axios';
import Add from './add';
import Edit from './edit';
import Table from './table';

const api = {
    getAllClients: "/api/clients"
}
const ClientsRoot = () => {
    const [clients, setClients] = useState([]);
    let location = useLocation();
    React.useEffect(() => {
        axios.get(api.getAllClients).then(res => setClients(res.data));
    }, [location, setClients]);

    let result = <></>;

    if(clients.length === 0)
        result = <BootstrapSpinner loadingMessage='Loading clients data ...'/>
    else 
        result = <Table clients={clients}/>;

    return (
        <>
            <div className="container-fluid p-0 pt-2">
                <Router hashType="noslash">
                    <Route exact path={"/"}>
                        {result}
                    </Route>
                    <Route exact path={"/Add"} component={Add}/>
                    <Route exact path={"/Edit/:id"} component={Edit}/>
                </Router>
            </div>
            {/* <ModalRoot/> */}
        </>
    );
}

export default ClientsRoot;