import React from 'react';
import { TextInput, CheckboxInput, TextAreaInput, MultiselectInput, SelectInput } from '../../react-final-forms/inputs'
import { Form } from 'react-final-form';
import {LoctaButton, PrimaryButton, SecondaryButton} from '../../bootstrap';

const CouncilForm = ({initialValues = null, onSubmit}) => {
    const { tagsOptions, councilCodes } = initialValues;
    let councilOptions = councilCodes ? councilCodes.map(c => (<option value={c.code} key={c.code}>{c.name}</option>)) : null;
    return (
        <div className='locta-form bg-grad p-3'>
            <Form
                onSubmit={onSubmit}
                initialValues={initialValues}
                render={({ handleSubmit, form, submitting, pristine }) => (
                    <form onSubmit={handleSubmit}>
                        <TextInput label="Name" name="name" placeholder="Council Name" required={true}/>
                        <SelectInput name="code" label="Linked to Local Authority">
                            <option value="">-- Please Select --</option>
                            {councilOptions}
                        </SelectInput>
                        <MultiselectInput label="Tags" name="tags" options={(tagsOptions || [])} allowCreate={true} showError={true} required={true} showSelectedItemsInList={true}/>
                        <TextInput label="Main User Contact Tel" name="mainUserContactTel" placeholder="Main Tel" />
                        <TextInput label="Council Tax Email Address" name="councilTaxEmail" placeholder="Council Tax Email" />
                        <TextInput label="Benefits Email Address" name="benefitsEmail" placeholder="Benefits Email" />
                        <TextInput label="NNDR Email Address" name="nndrEmail" placeholder="Nndr Email" />
                        <TextInput label="Extract File Query Email" name="extractFileQueryEmail" placeholder="ExtractFile Query Email" />
                        <br />
                        <CheckboxInput label="Allow File Imports" name="allowFileImports" />
                        <CheckboxInput label="Visible to HMRC" name="tphmrcVisible" placeholder="TPHMRC Visible" />
                        <CheckboxInput label="Show Employment Fields in Council Tax Results" name="showEmploymentFields" placeholder="Show Employment Fields" />
                        <CheckboxInput label="Is Active Council" name="isActive" placeholder="Is Active" />
                        <br />
                        <TextInput label="Postcode" name="postCode" placeholder="Postcode" required={true}/>
                        <TextInput label="Grid N" name="gridN" placeholder="Grid N" required={true}/>
                        <TextInput label="Grid E" name="gridE" placeholder="Grid E" required={true}/>
                        <TextInput label="Latitude" name="latitude" placeholder="Latitude" required={true}/>
                        <TextInput label="Longitude" name="longitude" placeholder="Longitude" required={true}/>
                        <br />
                        <CheckboxInput label="Extract Alert Emails: NNDR" name="extractAlertNNDR" placeholder="ExtractAlertNNDR" />
                        <CheckboxInput label="Extract Alert Emails: BENSDATA" name="extractAlertBENSDATA" placeholder="ExtractAlertBENSDATA" />
                        <CheckboxInput label="Extract Alert Emails: LOCTAPER" name="extractAlertLOCTAPER" placeholder="ExtractAlertLOCTAPER" />
                        <CheckboxInput label="Extract Alert Emails: LOCTAPRP" name="extractAlertLOCTAPRP" placeholder="ExtractAlertLOCTAPRP" />
                        <br />
                        <TextInput label="SFTP Username" name="sftpUsername" placeholder="SFTP Username"/>
                        <TextInput label="SFTP Password" name="sftpPassword" placeholder="SFTP Password" />
                        <TextAreaInput label="SFTP Public Key" name="sftpKey" placeholder="SFTP public Key" />


                        <ul className="inline-fields search-buttons">
                            <li className="align-right b4" style={{width:"100px", height:"28px"}}>
                                <LoctaButton type="submit" small={true} className="h-100 mt-20" disabled={submitting || pristine} ButtonFn={PrimaryButton} textComponent={<>Save</>} fontSize="18px" fontWeight="normal" />
                            </li>
                            <li className="align-right b4" style={{width:"100px", height:"28px"}}>
                                <LoctaButton small={true} className="h-100 mt-20" disabled={submitting || pristine} ButtonFn={SecondaryButton} textComponent={<>Reset</>} fontSize="18px" fontWeight="normal" onClick={form.reset}/>
                            </li>
                        </ul>
                    </form>
                )}
            />
        </div>

    )
};

export default CouncilForm;