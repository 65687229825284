import axios from 'axios';
export const FETCH_USERS_DATA_STARTED = 'FETCH_USERS_DATA_STARTED';
export const FETCH_USERS_DATA_FINISHED = 'FETCH_USERS_DATA_FINISHED';
export const FETCH_USERS_DATA_ERROR = 'FETCH_USERS_DATA_ERROR';
export const DELETE_USER_START = 'DELETE_USER_START';
export const DELETE_USER_END = 'DELETE_USER_END';
export const REENABLE_USER_START = 'REENABLE_USER_START';
export const REENABLE_USER_END = 'REENABLE_USER_END';
export const GET_USER_CHANGELOG_STARTED = 'GET_USER_CHANGELOG_STARTED';
export const GET_USER_CHANGELOG_FINISHED = 'GET_USER_CHANGELOG_FINISHED';
export const GET_USER_CHANGELOG_ERROR = 'GET_USER_CHANGELOG_ERROR';

export const CLIENTS_GETALL_STARTED = 'CLIENTS_GETALL_STARTED';
export const CLIENTS_GETALL_FINISHED = 'CLIENTS_GETALL_FINSHED';
export const CLIENTS_GETLOGINLINKS_STARTED = 'CLIENTS_GETLOGINLINKS_STARTED';
export const CLIENTS_GETLOGINLINKS_FINISHED = 'CLIENTS_GETLOGINLINKS_FINSHED';
export const CLIENTS_ADDLOGINLINKS_STARTED = 'CLIENTS_ADDLOGINLINKS_STARTED';
export const CLIENTS_ADDLOGINLINKS_FINISHED = 'CLIENTS_ADDLOGINLINKS_FINSHED';
export const CLIENTS_DELETELOGINLINKS_STARTED = 'CLIENTS_DELETELOGINLINKS_STARTED';
export const CLIENTS_DELETELOGINLINKS_FINISHED = 'CLIENTS_DELETELOGINLINKS_FINSHED';


export const COUNCILS_GETALL_STARTED = 'COUNCILS_GETALL_STARTED';
export const COUNCILS_GETALL_FINISHED = 'COUNCILS_GETALL_FINSHED';
export const DELETE_COUNCIL_START = 'DELETE_COUNCIL_START';
export const DELETE_COUNCIL_END = 'DELETE_COUNCIL_END';

const api = {
    getUsers:(query) => `/Users/GetAllUsers?clientName=${query}`,
    getChangeLog:(userId) => `/Users/GetChangeLog?userId=${userId}`,
    deleteUser:() => `/api/users/DeleteUser`,
    reenableUser: () => `/api/users/ReenableUser`,

    getClients: () => `/api/clients`,
    getClientsLoginLinks: (clientId) => `/api/clients/login-links/${clientId}`,
    addClientsLoginLink: (clientId) => `/api/clients/login-links/add/${clientId}`,
    deleteClientsLoginLink: (clientId, token) => `/api/clients/login-links/delete/${clientId}/${token}`,

    getCouncils: () => `/api/councils/get`,
    deleteCouncil: (id) => `/api/councils/delete/${id}`
}

const action = (type) => ({type});
const actionWithData = (type, data) => ({type, data});

const fetchUsersDataStarted = (props) => {
    return { type: FETCH_USERS_DATA_STARTED, data: props };
}

const fetchUsersDataFinished = (props) => {
    return { type: FETCH_USERS_DATA_FINISHED, data: props };
}

const  fetchUsersDataError = (props) => {
    return { type: FETCH_USERS_DATA_ERROR, data: props };
}

// --- users
export const fetchUsersData = () => {
    return (dispatch) => {
        dispatch(fetchUsersDataStarted());

        axios.get(api.getUsers()).then(response => {
           const data = response.data;
           if(data){
               dispatch(fetchUsersDataFinished({data}));
           } 
        }).catch(err => {
            dispatch(fetchUsersDataError({err}));
        });
    }
}

export const deleteUser = (userId = null) => {
    if(userId === null ) return;
    return dispatch => {
        dispatch(actionWithData(DELETE_USER_START, userId));

        axios.post(api.deleteUser(), {Id:userId}).then(res => {
            dispatch(actionWithData(DELETE_USER_END, userId));
            dispatch(fetchUsersData());
        });
    }
}

export const reenableUser = (userId = null) => {
    if(userId === null ) return;
    return dispatch => {
        dispatch(actionWithData(REENABLE_USER_START, userId));

        axios.post(api.reenableUser(), {Id:userId}).then(res => {
            dispatch(actionWithData(REENABLE_USER_END, userId));
            dispatch(fetchUsersData());
        });
    }
}

export const getUserChangeLog = (userId = null) => {
    if(userId === null) return;

    return dispatch => {
        dispatch(actionWithData(GET_USER_CHANGELOG_STARTED, userId));

        axios.get(api.getChangeLog(userId))
        .then(res => {
            dispatch(actionWithData(GET_USER_CHANGELOG_FINISHED, res.data));
        })
        .catch(err => {
            dispatch(actionWithData(GET_USER_CHANGELOG_ERROR, userId));
        });
    };
}


// --- clients
export const fetchClients = () => {
    return (dispatch) => {
        dispatch(action(CLIENTS_GETALL_STARTED));

        axios.get(api.getClients()).then(response => {
           const data = response.data;
           if(data){
               dispatch(actionWithData(CLIENTS_GETALL_FINISHED, data));
           } 
        }).catch(err => {
            console.warn(err);
        });
    }
}

export const fetchClientsLoginLinks = (clientId) => {
    return (dispatch) => {
        dispatch(action(CLIENTS_GETLOGINLINKS_STARTED));

        axios.get(api.getClientsLoginLinks(clientId)).then(response => {
           const data = response.data;
           if(data){
               dispatch(actionWithData(CLIENTS_GETLOGINLINKS_FINISHED, data));
           } 
        }).catch(err => {
            console.warn(err);
        });
    }
}

export const addToClientsLoginLinks = (clientId) => {
    return (dispatch) => {
        dispatch(action(CLIENTS_ADDLOGINLINKS_STARTED));

        axios.post(api.addClientsLoginLink(clientId)).then(response => {
           const data = response.data;
           if(data){
               dispatch(actionWithData(CLIENTS_ADDLOGINLINKS_FINISHED, data));
           } 
        }).catch(err => {
            console.warn(err);
        });
    }
}

export const deleteFromClientsLoginLinks = (clientId, token) => {
    return (dispatch) => {
        dispatch(action(CLIENTS_DELETELOGINLINKS_STARTED));

        axios.post(api.deleteClientsLoginLink(clientId, token)).then(response => {
           const data = response.data;
           if(data){
               dispatch(actionWithData(CLIENTS_DELETELOGINLINKS_FINISHED, data));
           } 
        }).catch(err => {
            console.warn(err);
        });
    }
}



// --- councils
export const fetchCouncils = () => {
    return (dispatch) => {
        dispatch(action(COUNCILS_GETALL_STARTED));

        axios.get(api.getCouncils()).then(response => {
           const data = response.data;
           if(data){
               dispatch(actionWithData(COUNCILS_GETALL_FINISHED, data));
           } 
        }).catch(err => {
            console.warn(err);
        });
    }
}

export const deleteCouncil = (id = null) => {
    if(id === null ) return;
    return dispatch => {
        dispatch(actionWithData(DELETE_COUNCIL_START, id));

        axios.post(api.deleteCouncil(id)).then(_ => {
            dispatch(actionWithData(DELETE_COUNCIL_END, id));
            dispatch(fetchUsersData());
        });
    }
}