import React from 'react';
import { TextInput, CheckboxInput, TextAreaInput, MultiselectInput, SelectInput } from '../../react-final-forms/inputs'
import { Form } from 'react-final-form';
import { LoctaButton, PrimaryButton, SecondaryButton, Row, Col } from '../../bootstrap';

const ClientForm = ({ initialValues = null, onSubmit }) => {
    if (initialValues == null) return null;
    const { councils } = initialValues;
    const datasetOptions = initialValues === null ? [] : initialValues.datasetOptions;
    let councilOptions = Object.keys(councils).map(c => (<option value={c} key={c}>{councils[c]}</option>));
    return (
        <div className='locta-form bg-grad p-3'>
            <Form
                onSubmit={onSubmit}
                initialValues={initialValues}
                render={({ handleSubmit, form, submitting, pristine }) => (
                    <form onSubmit={handleSubmit}>
                        <TextInput name="name" label="Name" placeholder="Client Name" required={true} />
                        <SelectInput name="councilId" label="Linked to Local Authority">
                            <option value="-1">-- Please Select --</option>
                            {councilOptions}
                        </SelectInput>
                        <MultiselectInput label="Linked to LA Datasets" name="datasets" options={datasetOptions} allowCreate={false} showCount={true} showSelectedItemsInList={true} filter='contains'/>
                        <TextInput name="passwordPolicyExpiration" label="Password Expiration" placeholder="Password Expiration" required={true} />
                        <CheckboxInput name="datamatchEnabled" label="NINO Match" />
                        <TextInput name="datamatchContactEmail" label="NINO Match Email" placeholder="NINO Match Email" />
                        <TextInput name="datamatchContactName" label="NINO Match Contact Name" placeholder="NINO Match Contact Name" />
                        <TextInput name="datamatchContactTel" label="NINO Match Contact Telephone" placeholder="NINO Match Contact Telephone" />
                        <CheckboxInput name="showNinoMatchDownloads" label="Show Nino Match Analysis Docs" />
                        <CheckboxInput name="sbrEnabled" label="SBRR/SBBS Alert Enabled" />
                        <TextInput name="sbrContactEmail" label="SBRR/SBBS Alert Email" placeholder="SBRR/SBBS Alert Email" />

                        <CheckboxInput name="dissolvedCompaniesEnabled" label="Dissolved Companies" />
                        <TextInput name="dissolvedCompaniesEmail" label="Dissolved Companies Email" placeholder="Dissolved Companies Email" />

                        <CheckboxInput name="annualLicense" label="Annual License" />

                        <CheckboxInput name="sendWelcomeEmails" label="Send Welcome Emails" />

                        <label className="span9"><u>Modules</u></label>

                        <CheckboxInput name="loctaPlusEnabled" label="LoCTA Plus Enabled" />
                        <CheckboxInput name="benefitsEnabled" label="Benefits Module Enabled" />
                        <CheckboxInput name="cTaxNameEnabled" label="Council Tax Name Module Enabled" />
                        <CheckboxInput name="cTaxAddressEnabled" label="Council Tax Address Module Enabled" />
                        <CheckboxInput name="businessRatesEnabled" label="Business Rates Module Enabled" />
                        <CheckboxInput name="companiesHouseEnabled" label="Companies House Module Enabled" />
                        <CheckboxInput name="votersRollEnabled" label="Voters Roll Module Enabled" />
                        <CheckboxInput name="votersRollByAddressEnabled" label="Voters Roll By Address Module Enabled" />
                        <CheckboxInput name="vrAlertsEnabled" label="Voters Roll Alerts Enabled" />
                        <CheckboxInput name="telephoneDirectoryEnabled" label="Telephone Directory Module Enabled" />
                        <CheckboxInput name="deceasedEnabled" label="Deceased Module Enabled" />
                        <CheckboxInput name="advancedBatchEnabled" label="Trace Batch Module Enabled" />
                        <CheckboxInput name="businessRatesBatchEnabled" label="Business Rates Batch Module Enabled" />
                        <CheckboxInput name="equifaxConsumerEnabled" label="Equifax Consumer Module Enabled" />
                        <CheckboxInput name="transUnion360ReportEnabled" label="TransUnion 360 Report Enabled" />
                        <CheckboxInput name="transUnionCallReportEnabled" label="TransUnion Call Report Enabled" />
                        <CheckboxInput name="quickSearchAlertsEnabled" label="Quick Search Alerts Enabled" />

                        <label className="span9"><u>Witness Protection / Withheld Records</u></label>
                        <CheckboxInput name="cTaxFilterEnabled" label="CTax Records" />
                        <CheckboxInput name="ninoFilterEnabled" label="Benefit Records" />

                        <label className="span9"><u>Alert Thresholds</u></label>

                        <TextInput name="thresholdBens" label="Benefit Threshold" placeholder="Benefit Threshold" />
                        <TextInput name="thresholdCtaxAddr" label="CTAX Address Threshold" placeholder="CTAX Address Threshold" />
                        <TextInput name="thresholdCtaxName" label="CTAX Name Threshold" placeholder="CTAX Name Threshold" />
                        <TextInput name="thresholdNndr" label="Business Rates Threshold" placeholder="Business Rates Threshold" />
                        <TextInput name="thresholdTelephone" label="Telephone Directory Threshold" placeholder="Telephone Directory Threshold" />
                        <TextInput name="thresholdVotersRoll" label="Voters Roll Threshold" placeholder="Voters Roll Threshold" />
                        <TextInput name="thresholdDeceased" label="Deceased Threshold" placeholder="Deceased Threshold" />
                        <TextInput name="thresholdAddr" label="Address Threshold" placeholder="Address Threshold" />
                        <Row className='d-flex align-items-center justify-content-end'>
                            <Col size={1}>
                                <LoctaButton small={true} className="h-100 mt-2" disabled={submitting || pristine} ButtonFn={SecondaryButton} textComponent={<>Reset</>} fontSize="18px" fontWeight="normal" onClick={form.reset} />
                            </Col>
                            <Col size={1} className='pl-2'>
                                <LoctaButton type="submit" small={true} className="h-100 mt-2" disabled={submitting || pristine} ButtonFn={PrimaryButton} textComponent={<>Save</>} fontSize="18px" fontWeight="normal" />
                            </Col>
                        </Row>

                        <ul className="inline-fields search-buttons">
                            <li className="align-right b4" style={{ width: "100px", height: "32px" }}>
                            </li>
                            <li className="align-right b4" style={{ width: "100px", height: "32px" }}>
                            </li>
                        </ul>
                    </form>
                )}
            />
        </div>

    )
};

export default ClientForm;