import React, { Component } from 'react';
import {connect} from 'react-redux';
import * as actions from '../../../redux-actions/superuser-actions';

class ListItem extends Component {
    constructor(props){
        super(props);

        this.onDeleteClick = this.onDeleteClick.bind(this);
    }

    onDeleteClick(ev, token) {
        ev.preventDefault();

        this.props.deleteFromClientsLoginLinks(this.props.clientId, this.props.token);
    }

    render() {
        const { token } = this.props;

        return (
            <li>
                https://www.loctatraining.co.uk/access/{token} <button className="btn btn-link red pull-right mr-20" onClick={this.onDeleteClick}>X</button>
            </li>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    deleteFromClientsLoginLinks: (clientId, token) => {
        dispatch(actions.deleteFromClientsLoginLinks(clientId, token));
    }
});

export default connect(null, mapDispatchToProps)(ListItem);
