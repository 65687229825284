import React from 'react';
import { useSelector } from "react-redux";
import { selector } from "../../../redux-reducers/locta-plus-v2-reducer";
import { Card, SuccessCard } from '../../bootstrap';
import SearchNames from '../../searches-results/search-types';


const benefitsForm = ({ search }) => (
    <form method="POST" action="/Search/Benefits" target="_blank">
        <input type="hidden" name="Surname" value={ search.Surname } />
        <input type="hidden" name="Nino" value={ search.Nino } />
        <input type="hidden" name="DateOfBirth" value={ Date.loctaFormFormat(search.DateOfBirth) } />
        <input type="hidden" name="OpenCasesOnly" value={ search.IsLive ? "true" : "false" } />

        <input type="hidden" name="NeighbouringCouncilId" value={ search.NeighbouringCouncilId } />
        <input type="hidden" name="MaxSearchDistance" value={ search.MaxSearchDistance } />

        <input type="hidden" name="InitialSearch" value={SearchNames.Benefits} />
        <input type="hidden" name="LinkedSearch" value="" />
        <input type="hidden" name="SecondLinkedSearch" value="" />
        <input type="hidden" name="IsLoctaPlusSearch" value="true" />
        <input type="hidden" name="LoctaPlusSearch" value="true" />
        <input type="hidden" name="IsLinkedSearch" value="false" />

        <button type="submit" className={ 'btn btn-success align-right btn-sm' }><i className="icon-play"></i>View</button>
    </form>
);

const deceased = ({ search }) => (
    <form method="POST" action="/Search/Deceased" target="_blank">
        <input type="hidden" name="Forename" value={ search.Forename } />
        <input type="hidden" name="Surname" value={ search.Surname } />
        <input type="hidden" name="DateOfBirtch" value={ search.DateOfBirth } />
        <input type="hidden" name="Location" value={ search.Location } />
        <input type="hidden" name="Postcode" value={ search.PostCode } />

        <input type="hidden" name="NeighbouringCouncilId" value={ search.NeighbouringCouncilId } />
        <input type="hidden" name="MaxSearchDistance" value={ search.MaxSearchDistance } />

        <input type="hidden" name="InitialSearch" value={SearchNames.Deceased} />
        <input type="hidden" name="LinkedSearch" value="" />
        <input type="hidden" name="SecondLinkedSearch" value="" />
        <input type="hidden" name="IsLoctaPlusSearch" value="true" />
        <input type="hidden" name="LoctaPlusSearch" value="true" />
        <input type="hidden" name="IsLinkedSearch" value="false" />
        
        <button type="submit" className={ 'btn btn-success align-right btn-sm' }><i className="icon-play"></i>View</button>
    </form>
);

const councilTaxAddress = ({ search }) => (
    <form method="POST" action="/Search/CouncilTaxAddress" target="_blank">
        <input type="hidden" name="HouseNameNumber" value={ search.HouseNameNumber } />
        <input type="hidden" name="StreetName" value={ search.StreetName } />
        <input type="hidden" name="Postcode" value={ search.PostCode } />
        <input type="hidden" name="IsLive" value={ search.IsLive ? "true" : "false" } />       

        <input type="hidden" name="NeighbouringCouncilId" value={ search.NeighbouringCouncilId } />
        <input type="hidden" name="MaxSearchDistance" value={ search.MaxSearchDistance } />

        <input type="hidden" name="InitialSearch" value={SearchNames.CouncilTaxAddress} />
        <input type="hidden" name="LinkedSearch" value="" />
        <input type="hidden" name="SecondLinkedSearch" value="" />
        <input type="hidden" name="IsLoctaPlusSearch" value="true" />
        <input type="hidden" name="LoctaPlusSearch" value="true" />
        <input type="hidden" name="IsLinkedSearch" value="false" />
        
        <button type="submit" className={ 'btn btn-success align-right btn-sm' }><i className="icon-play"></i>View</button>
    </form>
);

const councilTaxName = ( { search } ) => (
    <form method="POST" action="/Search/CouncilTaxName" target="_blank">        
        <input type="hidden" name="Forename" value={ search.Forename } />
        <input type="hidden" name="BlankForename" value={ search.BlankForename } />
        <input type="hidden" name="Surname" value={ search.Surname } />
        <input type="hidden" name="SearchStart" value={ Date.loctaFormFormat(search.SearchStart) } />
        <input type="hidden" name="SearchEnd" value={ Date.loctaFormFormat(search.SearchEnd) } />
        <input type="hidden" name="IsLive" value={ search.IsLive ? "true" : "false" } />
        <input type="hidden" name="IsExact" value="false" />

        <input type="hidden" name="NeighbouringCouncilId" value={ search.NeighbouringCouncilId } />
        <input type="hidden" name="MaxSearchDistance" value={ search.MaxSearchDistance } />

        <input type="hidden" name="InitialSearch" value={SearchNames.CouncilTaxName} />
        <input type="hidden" name="LinkedSearch" value="" />
        <input type="hidden" name="SecondLinkedSearch" value="" />
        <input type="hidden" name="IsLoctaPlusSearch" value="true" />
        <input type="hidden" name="LoctaPlusSearch" value="true" />
        <input type="hidden" name="IsLinkedSearch" value="false" />
        
        <button type="submit" className={ 'btn btn-success align-right btn-sm' }><i className="icon-play"></i>View</button>
    </form>
);

const directory = ({ search }) => (
    <form method="POST" action="/Search/Directory" target="_blank">
        <input type="hidden" name="Surname" value={ search.Surname } />
        <input type="hidden" name="Location" value={ search.Location } />

        <input type="hidden" name="NeighbouringCouncilId" value={ search.NeighbouringCouncilId } />
        <input type="hidden" name="MaxSearchDistance" value={ search.MaxSearchDistance } />

        <input type="hidden" name="InitialSearch" value={SearchNames.Directory} />
        <input type="hidden" name="LinkedSearch" value="" />
        <input type="hidden" name="SecondLinkedSearch" value="" />
        <input type="hidden" name="IsLoctaPlusSearch" value="true" />
        <input type="hidden" name="LoctaPlusSearch" value="true" />
        <input type="hidden" name="IsLinkedSearch" value="false" />
        
        <button type="submit" className={ 'btn btn-success align-right btn-sm' }><i className="icon-play"></i>View</button>
    </form>
);

const votersRollForm = ( { search } ) => (
    <form method="POST" action="/Search/VotersRoll" target="_blank">
        <input type="hidden" name="Forename" value={ search.Forename } />
        <input type="hidden" name="Surname" value={ search.Surname } />
        <input type="hidden" name="IsExact" value="false"/>

        <input type="hidden" name="NeighbouringCouncilId" value={ search.NeighbouringCouncilId } />
        <input type="hidden" name="MaxSearchDistance" value={ search.MaxSearchDistance } />

        <input type="hidden" name="InitialSearch" value={SearchNames.VotersRoll} />
        <input type="hidden" name="LinkedSearch" value="" />
        <input type="hidden" name="SecondLinkedSearch" value="" />
        <input type="hidden" name="IsLoctaPlusSearch" value="true" />
        <input type="hidden" name="LoctaPlusSearch" value="true" />
        <input type="hidden" name="IsLinkedSearch" value="false" />

        <button type="submit" className={ 'btn btn-success align-right btn-sm' }><i className="icon-play"></i>View</button>
    </form>
);

const ButtonSwitch = ({ searchType, ...rest }) => {
    switch(searchType)
    {
        case SearchNames.Benefits:
            return benefitsForm(rest);

        case SearchNames.Deceased:
            return deceased(rest);

        case SearchNames.CouncilTaxAddress:
            return councilTaxAddress(rest);

        case SearchNames.CouncilTaxName:
            return councilTaxName(rest);

        case SearchNames.Directory:
            return directory(rest);

        case SearchNames.VotersRoll:
            return votersRollForm(rest);

        default:
            return null;
    }
};

const ResultCard = ({title, resultsCount = 0, searchType = SearchNames.None}) => {
    const searchedValues = useSelector(selector.searchedValues);
    if(searchType === SearchNames.None) return null;

    const hasResults = resultsCount > 0;
    const CardSwitch = hasResults ? SuccessCard : Card;
    let viewButton = resultsCount > 0 ? <ButtonSwitch searchType={searchType} search={searchedValues}/> : null;
    let content = <div className="card-text d-flex justify-content-between align-items-top">{resultsCount} Results {viewButton}</div>
    return <CardSwitch title={title}>{content}</CardSwitch>;
}

export default ResultCard;