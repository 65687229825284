import React, { useMemo } from "react";
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { useSelector } from 'react-redux';
import { initialValues, councils } from './server-values';
import searchConfig from '../config';
import { formName } from '../../../redux-actions/locta-plus-v2-actions';
import { renderInput, renderDateTimePicker, renderSelectList, renderPostcodeInput, renderCheckboxInline } from '../../shared/search-inputs';


const SearchForm = ({ handleSubmit }) => {

    const selector = formValueSelector(formName);
    const { maxSearchDistance } = useSelector(state => selector(state, '', 'maxSearchDistance'));
    const maxSearchDistanceInt = parseInt(maxSearchDistance);

    const councilOptions = useMemo(() => {
        let opts = Object.keys(councils).map(c => (<option value={c} key={c}>{councils[c]}</option>));
        opts.sort((a, b) => a.props.children.localeCompare(b.props.children));
        return opts;
    }, []);

    const tabIndex = 17;
    return (
        <div className={"block-grey"}>
            <form className="form-search rf" autoComplete="off" onSubmit={handleSubmit}>
                <div name="hidden-fields">
                    <Field name="__requestVerificationToken" component="input" type="hidden" />
                </div>

                <div>
                    <ul className="inline-fields">
                        <li>
                            <Field name="forename" type="text" component={renderInput} label="Forename" placeholder="Forename" tabIndex={tabIndex} />
                        </li>
                        <li>
                            <Field name="surname" type="text" component={renderInput} label="Surname" placeholder="Surname" tabIndex={tabIndex} />
                        </li>
                    </ul>
                    <ul className="inline-fields">
                        <li>
                            <Field name="blankForename" component={renderCheckboxInline} label="Blank Forename" tabIndex={tabIndex} />
                        </li>
                    </ul>
                    <ul className="inline-fields">
                        <li>
                            <Field name="dateOfBirth" component={renderDateTimePicker} label="Date of Birth" tabIndex={tabIndex} />
                        </li>
                        <li>
                            <Field name="nino" type="text" component={renderInput} label="Nino" placeholder="NINO" tabIndex={tabIndex} />
                        </li>
                    </ul>
                    <ul className="inline-fields">
                        <li>
                            <Field name="houseNameNumber" type="text" component={renderInput} label="House Name / Number" placeholder="House Name / Number" tabIndex={tabIndex} />
                        </li>
                        <li>
                            <Field name="StreetName" type="text" component={renderInput} label="Street Name" placeholder="Street Name" tabIndex={tabIndex} />
                        </li>
                    </ul>
                    <ul className="inline-fields">
                        <li>
                            <Field name="postcode" type="text" component={renderPostcodeInput} tabIndex={tabIndex} label="Postcode" />
                        </li>
                        <li>
                            <Field name="location" type="text" component={renderInput} label="Location" placeholder="Location" tabIndex={tabIndex} />
                        </li>
                    </ul>
                    <ul className="inline-fields">
                        <li>
                            <Field name="searchStart" component={renderDateTimePicker} label="Search Start" tabIndex={tabIndex} />
                        </li>
                        <li>
                            <Field name="searchEnd" component={renderDateTimePicker} label="Search End" tabIndex={tabIndex} />
                        </li>
                    </ul>
                    <ul className="inline-fields">
                        <li>
                            <Field name="isLive" component={renderCheckboxInline} label="Live Cases Only" tabIndex={tabIndex} />
                        </li>
                    </ul>

                    <ul className="inline-fields">
                        <li>
                            <label><span>By Distance from Council</span></label>
                            <Field name="neighbouringCouncilId" component={renderSelectList} tabIndex={tabIndex} >
                                <option value="0">Current Council</option>
                                {councilOptions}
                            </Field>
                            <span style={{ paddingLeft: '4px' }}>
                                <Field name="maxSearchDistance" component="input" type="range" className="white" min={searchConfig.sliderMinSearchDistance} max={searchConfig.sliderMaxSearchDistance} step={searchConfig.sliderDistanceStep} tabIndex={tabIndex} />
                            </span>
                            <span style={{ lineHeight: '30px' }}>{maxSearchDistanceInt >= 640 ? 'National' : `${maxSearchDistanceInt} miles`}</span>
                        </li>
                    </ul>
                </div>
            </form>
        </div>
    );
}


export default reduxForm({
    form: formName,
    initialValues
})(SearchForm);