import axios from 'axios';
import { initialize, SubmissionError, clearSubmitErrors } from 'redux-form';

export const COUNCIL_TAX_NAME_SEARCH_START = 'COUNCIL_TAX_NAME_SEARCH_START';
export const COUNCIL_TAX_NAME_SEARCH_SUCCESS = 'COUNCIL_TAX_NAME_SEARCH_SUCCESS';
export const COUNCIL_TAX_NAME_SEARCH_FAIL = 'COUNCIL_TAX_NAME_SEARCH_FAIL';
export const COUNCIL_TAX_NAME_SEARCH_RESET = 'COUNCIL_TAX_NAME_SEARCH_RESET';

export const urls = {
    search: `/api/search/council-tax-name`,
    validate: `/api/search/council-tax-name/validate`
}

export const formName = "council-tax-name-search";

class CouncilTaxNameSearchViewModel {
    constructor(model) {
        this.Forename = model.forename;
        this.BlankForename = model.blankForename;
        this.Surname = model.surname;
        this.NeighbouringCouncilId = model.neighbouringCouncilId;
        this.SearchStart = Date.formatForServer(model.searchStart);
        this.SearchEnd = Date.formatForServer(model.searchEnd);
        this.IsLive = model.isLive;
        this.IsExact = model.isExact;
        this.Telephone = model.telephone;
        this.Email = model.email;
        this.SearchArea = model.searchArea;
        this.MaxSearchDistance = model.maxSearchDistance;

        this.InitialSearch = model.initialSearch;
        this.LinkedSearch = model.linkedSearch;
        this.SecondLinkedSearch = model.secondLinkedSearch;
        this.IsLoctaPlusSearch = model.isLoctaPlusSearch;
        this.IsQuickSearch = model.isQuickSearch;

        this.__RequestVerificationToken = model.__requestVerificationToken;
    }
}

const action = (type, data = null) => ({
    type, data
});

export function performSearch(model) {
    return async (dispatch) => {

        dispatch(action(COUNCIL_TAX_NAME_SEARCH_START, model));

        const formValues = new CouncilTaxNameSearchViewModel(model);

        const validateResponse = await axios
            .post(urls.validate, formValues);
        if (Object.keys(validateResponse.data).length)
            throw new SubmissionError(validateResponse.data);
        try {
            const searchResponse = await axios.post(urls.search, formValues);
            dispatch(action(COUNCIL_TAX_NAME_SEARCH_SUCCESS, searchResponse.data || []));
        }
        catch (err) {
            dispatch(action(COUNCIL_TAX_NAME_SEARCH_FAIL));
        }
    }
}


export function validateSearch(model) {
    return async (dispatch) => {
        const formValues = new CouncilTaxNameSearchViewModel(model);

        dispatch(clearSubmitErrors(formName));

        const response = await axios.post(urls.validate, formValues);
        if (Object.keys(response.data).length)
            throw response.data;
    }
}

export function resetSearch(values) {
    return (dispatch) => {

        dispatch(action(COUNCIL_TAX_NAME_SEARCH_RESET));
        dispatch(initialize(formName, {
            ...values,
            surname: '',
            nino: '',
            openCasesOnly: false
        }));
    }
}


