import React from 'react';
import { Row, Col, Hr } from '../../bootstrap';
import OccupantsView from './occupants';
import { Field } from '../field';

export default ({ data }) => {
    const {
        id,
        reference,
        persRef,
        forename,
        surname,
        middleName,
        maidenName,
        councilTaxBand,
        startDateFormatted,
        endDateFormatted,
        liabilityOrderGranted,
        address,
        forwardingAddress,
        ownerLiabilityInd,
        propertyOwnerName,
        propertyOwnerAddress,
        benefitsClaimIndicator,
        liveExemption,
        statusCode,
        singlePersonDiscount,
        disegardsOnlyDiscount,
        singleDisregardedPersonDiscount,
        twoOccupantsOneDisregardedDiscount,
        disabledDiscount,
        secondHomeDiscount,
        emptyProperty,
        propertyCurrentExempt,
        longTermEmptyHome,
        noDiscount,
        wardInformation,
        parishInformation,
        nlpg,
        liveInd,
        compositeInd,
        telephoneNumber,
        dayTelephoneNuber,
        nightTelephoneNumber,
        mobileNumber,
        emailAddress,
        methodOfPayment,
        employerName,
        employerRef,
        employerAddr,
        occupants
    } = data;
    return (
        <>
            <Row className="pb-3">
                <Col size={4}>
                    <Field label="Claim Reference" value={reference} />
                </Col>
                <Col size={4}>
                    <Field label="Person Reference" value={persRef} />
                </Col>
                <Col size={4}>
                    <Row>
                        <Col size={6}>
                            <Field label="Forename" value={forename} />
                        </Col>
                        <Col size={6}>
                            <Field label="Surname" value={surname} />
                        </Col>
                    </Row>
                </Col>

            </Row>
            <Row className="pb-3">
                <Col size={4}>
                    <Field label="Middle Name" value={middleName} />
                </Col>
                <Col size={4}>
                    <Field label="Maiden Name" value={maidenName} />
                </Col>
                <Col size={4}>
                    <Field label="Band" value={councilTaxBand} />
                </Col>
            </Row>
            <Row className="pb-3">
                <Col size={4}>
                    <Field label="Start Date" value={startDateFormatted} />
                </Col>
                <Col size={4}>
                    <Field label="End Date" value={endDateFormatted} />
                </Col>
                <Col size={4}>
                    <Field label="Liability Order Granted" value={liabilityOrderGranted} />
                </Col>
            </Row>
            <Hr />
            <Row className="pb-3">
                <Field label="Address" value={address} />
            </Row>
            <OccupantsView data={occupants} id={id} />
            <Row className="pb-3">
                <Field label="Forwarding Address" value={forwardingAddress} />
            </Row>
            <Hr />
            <Row className="pb-3">
                <Col size={4}>
                    <Field label="Owner Liability Ind" value={ownerLiabilityInd} />
                </Col>
                <Col size={8}>
                    <Field label="Property Owner Name" value={propertyOwnerName} />
                </Col>
            </Row>
            <Row className="pb-3">
                <Field label="Property Owner Address" value={propertyOwnerAddress} />
            </Row>
            <Row className="pb-3">
                <Col size={4}>
                    <Field label="Benefits Claim Indicator" value={benefitsClaimIndicator} />
                </Col>
                <Col size={4}>
                    <Field label="Live Exemption Indicator" value={liveExemption} />
                </Col>
                <Col size={4}>
                    <Field label="Status Code" value={statusCode} />
                </Col>
            </Row>
            <Row className="pb-3">
                <Col size={4}>
                    <Field label="Single Person Discount" value={singlePersonDiscount} />
                </Col>
                <Col size={4}>
                    <Field label="Disregards Only Discount" value={disegardsOnlyDiscount} />

                </Col>
                <Col size={4}>
                    <Field label="Single Disregarded Person Discount" value={singleDisregardedPersonDiscount} />
                </Col>
            </Row>
            <Row className="pb-3">
                <Col size={4}>
                    <Field label="Two Occupants One Disregarded Discount" value={twoOccupantsOneDisregardedDiscount} />
                </Col>
                <Col size={4}>
                    <Field label="Disabled Discount" value={disabledDiscount} />
                </Col>
                <Col size={4}>
                    <Field label="Second Home Discount" value={secondHomeDiscount} />
                </Col>
            </Row>
            <Row className="pb-3">
                <Col size={4}>
                    <Field label="Empty Property" value={emptyProperty} />
                </Col>
                <Col size={4}>
                    <Field label="Property Current Exempt" value={propertyCurrentExempt} />
                </Col>
                <Col size={4}>
                    <Field label="Long Term Empty Home" value={longTermEmptyHome} />
                </Col>
            </Row>
            <Row className="pb-3">
                <Col size={4}>
                    <Field label="No Discount" value={noDiscount} />
                </Col>
                <Col size={4}>
                    <Field label="Ward Information" value={wardInformation} />
                </Col>
                <Col size={4}>
                    <Field label="ParishInformation" value={parishInformation} />
                </Col>
            </Row>
            <Row className="pb-3">
                <Col size={4}>
                    <Field label="NLPG" value={nlpg} />
                </Col>
                <Col size={4}>
                    <Field label="Live indicator" value={liveInd} />
                </Col>
                <Col size={4}>
                    <Field label="Composite indicator" value={compositeInd} />
                </Col>
            </Row>
            <Row className="pb-3">
                <Col size={4}>
                    <Field label="Telephone Number" value={telephoneNumber} />
                </Col>
                <Col size={4}>
                    <Field label="Daytime Telephone Number" value={dayTelephoneNuber} />
                </Col>
                <Col size={4}>
                    <Field label="Night Telephone Number" value={nightTelephoneNumber} />
                </Col>
            </Row>
            <Row className="pb-3">
                <Col size={4}>
                    <Field label="Mobile" value={mobileNumber} />
                </Col>
                <Col size={4}>
                    <Field label="Email Address" value={emailAddress} />
                </Col>
                <Col size={4}>
                    <Field label="Method Of Payment" value={methodOfPayment} />
                </Col>
            </Row>
            <Row className="pb-3">
                <Col size={4}>
                    <Field label="Employer Name" value={employerName} />
                </Col>
                <Col size={8}>
                    <Field label="Employer Ref" value={employerRef} />
                </Col>
            </Row>
            <Row className="pb-3">
                <Field label="Employer Address" value={employerAddr} />
            </Row>
        </>
    )
}
