import React from 'react';

export default [
    {
        id: 'details',
        accessor: (row) => {
            return { 
                councilName: row.councilName, 
                distance: row.distance,
                recordsFound: row.recordsFound,
                lastUpdated: row.lastUpdated,
                contactNumber: row.contactNumber
            };
        },
        Cell: props => (
            <>
                <div className="results-col results-title">
                    <h4>{ props.value.councilName }</h4>
                    <span className="results-distance"><span>{ props.value.distance.toFixed(0) }</span> miles away</span>
                </div>
                <div className="results-col results-found">
                    Records Found - <span className="blue">{ props.value.recordsFound }</span>
                </div>
                <div className="results-col results-updated">
                    Last Updated - <span className="blue">{ props.value.lastUpdated }</span>
                </div>
                <div className="results-col results-contact">
                    Council Contact - <span className="blue">{ props.value.contactNumber }</span>
                </div>
            </>
        ) 
    },
    {
        id: 'buttons',
        expander: true,
        width: 250,
        Expander: props => (
            <div className="results-col results-link">
                <button className={"results-toggle btn btn-success double-lined" + (props.isExpanded ? " expanded" : " collapsed")}>
                    <i className="icon-play"></i>
                    <div>
                        <span>{props.isExpanded ? "collapse" : "expand"}</span>
                        <br />
                        results
                    </div>
                </button>
            </div>
        )
    },
    {
        accessor: 'records',
        show: false
    }
];