import DomConfigRepository from '../../../repositories/domConfigRepository';
import config from '../config';

const sv = DomConfigRepository.getJsonConfigById('searchValues');

const serverValues = {
    loctaPlusSearch: true,
    forename: sv.Forename,
    surname: sv.Surname,
    blankForename: sv.BlankForename,
    companyName: sv.CompanyName,
    location: sv.Location,
    nino: sv.Nino,
    houseNameNumber: sv.HouseNameNumber,
    streetName: sv.StreetName,
    postCode: sv.PostCode,
    neighbouringCouncilId: sv.NeighbouringCouncilId,
    dateOfBirth: Date.parseForReact(sv.DateOfBirth),
    searchStart: Date.parseForReact(sv.SearchStart),
    searchEnd: Date.parseForReact(sv.SearchEnd),
    isLive: sv.IsLive,
    key: "",
    __RequestVerificationToken: sv.__RequestVerificationToken,
    maxSearchDistance: typeof(sv.maxSearchDistance) === 'undefined' || sv.maxSearchDistance === 0 ? config.sliderStartingValue : sv.maxSearchDistance
  };

export const initialValues = {...serverValues};
export const councils = sv.Councils || {};