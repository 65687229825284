import React from 'react';
import { useDispatch } from "react-redux";
import { hideModal, showModal } from '../../../redux-actions/modal-actions';
import { ModalSizes, ModalTypes } from '../../modals';
import { Row, DangerButton, PrimaryButton, LinkButton } from '../../bootstrap';
import { deleteCouncil } from '../../../redux-actions/superuser-actions';
import { Link } from 'react-router-dom';

export const DeleteCouncilButton = (props) => {
    const dispatch = useDispatch();
    const hideModalFn = () => dispatch(hideModal());
    const showModalFn = (modalProps, modalType, modalSize) => {
        dispatch(showModal({ modalProps, modalType, modalSize }))
    };
    const onDeleteCouncil = (id) => { dispatch(deleteCouncil(id)) }

    const deleteCouncilOnClick = () => {
        const { Name, Id } = props.value;
        showModalFn({
            open: true,
            title: 'Delete Council?',
            message: `Are you sure you want to delete this council - ${Name}?`,
            cancelText: 'Cancel',
            text: 'Delete',
            action: () => {
                onDeleteCouncil(Id)
                hideModalFn();
            },
            closeModal: hideModalFn
        }, ModalTypes.danger, ModalSizes.default)
    };

    return (
        <Row center>
            <DangerButton onClick={deleteCouncilOnClick} small>Delete</DangerButton>
        </Row>
    );
}

export const EditCouncilButton = ({ value: { id } }) => {
    return (
        <Row center>
            <Link
                to={{
                    pathname: `Edit/${id}`
                }}
            >
                <PrimaryButton small>Edit</PrimaryButton>
            </Link>
        </Row>
    );
};

export const HoldingAreaLink = ({ value: { id } }) => {
    return (
        <Row center>
            <LinkButton onClick={() => window.open(`https://files.loctahub.uk/files/holding_area/${id}/`, "_blank")} small>Holding Area</LinkButton>
        </Row>
    );
};

export const ArchiveAreaLink = ({ value: { id } }) => {
    return (
        <Row center>
            <LinkButton onClick={() => window.open(`https://files.loctahub.uk/files/archive_area/${id}/`, "_blank")} small>Archive Area</LinkButton>
        </Row>
    );
};