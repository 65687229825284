import DomConfigRepository from '../../repositories/domConfigRepository';

const sv = DomConfigRepository.getJsonConfigById('searchValues');

export const initialValues = {
	forename: sv.forename,
	surname: sv.surname,
	blankForename: sv.blankForename,
	companyName: sv.companyName,
	location: sv.location,
	dateOfBirth: sv.dateOfBirth === "" ? null : sv.dateOfBirth,
	nino: sv.nino,
	houseName: sv.houseName,
	streetName: sv.streetName,
	postcode: sv.postcode,
	neighbouringCouncilId: sv.neighbouringCouncilId,
	maxSearchDistance: sv.maxSearchDistance,
	searchStart: sv.searchStart === "" ? null : sv.searchStart,
	searchEnd: sv.searchEnd === "" ? null : sv.searchEnd,
	liveCasesOnly: sv.liveCasesOnly,
    searchBenefits: sv.searchBenefits,
    searchCouncilTaxName: sv.searchCouncilTaxName,
    searchCouncilTaxAddress: sv.searchCouncilTaxAddress,
    searchBusinessRates: sv.searchBusinessRates,
    searchDeceased: sv.searchDeceased,
    searchVotersRoll: sv.searchVotersRoll,
    searchVotersRollByAddress: sv.searchVotersRollByAddress,
    searchDirectory: sv.searchDirectory,
    searchAll: false
}

export const councils = sv.councils || [];
