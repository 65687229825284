import React from 'react';
import store from '../redux-stores/superuser-store';
import Users from '../react-components/super-user/users';
import Councils from '../react-components/super-user/councils';
import Clients from '../react-components/super-user/clients';
import NinoMatch from '../react-components/nino-match';
import SBR from '../react-components/sbr';
import LogsDashboard from '../react-components/super-user/dashboards/logsDashboard';
import DataDashboard from '../react-components/super-user/dashboards/dataDashboard';
import DissolvedCompanies from '../react-components/dissolved-companies';
import ClientLoginLinks from '../react-components/super-user/client-login-links';
import Base from './base';
import { BrowserRouter as Router, Route } from "react-router-dom";
import {BootstrapContainer} from '../react-components/bootstrap';
const Routes = () => {
    return (
        <BootstrapContainer style={{height:"100%"}}>
            <Router>
                <Route path="/sbrservice" component={SBR} />
                <Route path="/ninoMatch" component={NinoMatch} />
                <Route path="/dissolvedcompanies" component={DissolvedCompanies} />
                <Route path="/dashboards/logsdashboard" component={LogsDashboard} />
                <Route path="/dashboards/datadashboard" component={DataDashboard} />
                <Route path="/Users" component={Users} />
                <Route path="/Clients/AddLoginLinks" component={ClientLoginLinks} />
                <Route path="/Clients" component={Clients} />
                <Route path="/Councils" component={Councils} />
            </Router>
        </BootstrapContainer>
    );
};


export default Base('react-app-superuser', store, Routes);
