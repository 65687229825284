import DomConfigRepository from '../../../repositories/domConfigRepository';
import config from '../config';

const sv = DomConfigRepository.getJsonConfigById('searchValues');
export const initialValues = {
    forename: sv.forename,
    surname: sv.surname,
    neighbouringCouncilId: sv.neighbouringCouncilId,
    searchStartDate: Date.parseForReact(sv.searchStartDate),
    searchEndDate: Date.parseForReact(sv.searchEndDate),
    isLive: sv.isLive,
    blankForename: sv.blankForename,
    searchArea: sv.searchArea,
    maxSearchDistance: typeof(sv.maxSearchDistance) === 'undefined' || sv.maxSearchDistance === 0 ? config.sliderStartingValue : sv.maxSearchDistance,
    isExact: sv.isExact,
    telephone: sv.telephone,
    email: sv.email,
    
    initialSearch: sv.initialSearch,
    linkedSearch: sv.linkedSearch,
    secondLinkedSearch: sv.secondLinkedSearch,

    isLinkedSearch: sv.isLinkedSearch,
    isLoctaPlusSearch: sv.isLoctaPlusSearch,
    isQuickSearch: sv.isQuickSearch
};

export const isAutoSearch = (initialValues.isLoctaPlusSearch || initialValues.isQuickSearch) && initialValues.isLinkedSearch === false;
export const councils = sv.councils || {};