import * as Actions from '../redux-actions/superuser-actions';

const initialState = {
    clients: [],
    loginLinks: []
};

export default (state = initialState, action) => {
    switch (action.type) {

        case Actions.CLIENTS_GETALL_STARTED:
            return {...state, initialState};

        case Actions.CLIENTS_GETALL_FINISHED:
            return {...state, clients: action.data, loginLinks: []};

        case Actions.CLIENTS_GETLOGINLINKS_FINISHED:
        case Actions.CLIENTS_ADDLOGINLINKS_FINISHED:
        case Actions.CLIENTS_DELETELOGINLINKS_FINISHED:
            return {...state, loginLinks: action.data};

        default:
            return state;
    }
};

export const selector = {
    clients: (state) => state.clients.clients,
    loginLinks: (state) => state.clients.loginLinks
};