import axios from 'axios';
import config from '../react-components/searches/config';
import { initialize, SubmissionError, clearSubmitErrors } from 'redux-form';

export const LOCTA_PLUS_V2_SEARCH_RESET = 'LOCTA_PLUS_V2_SEARCH_RESET';

export const LOCTA_PLUS_V2_PERFORMSEARCH_START = 'LOCTA_PLUS_V2_PERFORMSEARCH_START';
export const LOCTA_PLUS_V2_PERFORMSEARCH_SUCCESS = 'LOCTA_PLUS_V2_PERFORMSEARCH_SUCCESS';
export const LOCTA_PLUS_V2_PERFORMSEARCH_FAIL = 'LOCTA_PLUS_V2_PERFORMSEARCH_FAIL';


export const urls = {
    search: `/api/search/locta-plus/search`,
    validate: `/api/search/locta-plus/validate`
}

export const formName = "locta-plus-v2-search";

export const baseValues = {
    loctaPlusSearch: true,
    forename: "",
    surname: "",
    blankForename: false,
    companyName: "",
    location: "",
    dateOfBirth: null,
    nino: "",
    houseNameNumber: "",
    streetName: "",
    postCode: "",
    neighbouringCouncilId: 0,
    searchStart: null,
    searchEnd: null,
    isLive: false,
    runBenefits: false,
    runCTaxName: false,
    runCTaxAddress: false,
    runVotersRoll: false,
    runBusinessRates: false,
    runDeceased: false,
    runDirectory: false,
    key: "",
    maxSearchDistance: config.sliderStartingValue
};

class LoctaPlusV2SearchViewModel {
    constructor(formValues) {
        this.Forename = formValues.forename;                
        this.Surname = formValues.surname;                 
        this.BlankForename = formValues.blankForename;           
        this.Nino = formValues.nino;                    
        this.CompanyName = formValues.companyName;             
        this.Location = formValues.location;                
        this.PostCode = formValues.postCode;                
        this.HouseNameNumber = formValues.houseNameNumber;         
        this.StreetName = formValues.streetName;              
        this.MaxSearchDistance = formValues.maxSearchDistance;       
        this.NeighbouringCouncilId = formValues.neighbouringCouncilId;   
        this.IsLive = formValues.isLive;
        
        this.DateOfBirth = Date.formatForServer(formValues.dateOfBirth);          
        this.SearchStart = Date.formatForServer(formValues.searchStart);          
        this.SearchEnd = Date.formatForServer(formValues.searchEnd);            
        
        this.InitialSearch = formValues.initialSearch;
        this.LinkedSearch = formValues.linkedSearch;
        this.SecondLinkedSearch = formValues.secondLinkedSearch;
        this.LoctaPlusSearch = formValues.loctaPlusSearch;
        this.IsQuickSearch = formValues.isQuickSearch;

        this.__RequestVerificationToken = formValues.__requestVerificationToken;
    }
}

const action = (type, data = null) => ({
    type, data
});

export const search = (formValues) => {
    return async (dispatch) => {

        const viewModel = new LoctaPlusV2SearchViewModel(formValues);
        dispatch(action(LOCTA_PLUS_V2_PERFORMSEARCH_START, viewModel));
        goToStep(3);

        const validateResponse = await axios.post(urls.validate, viewModel);

        if (Object.keys(validateResponse.data).length)
        {
            dispatch(action(LOCTA_PLUS_V2_PERFORMSEARCH_FAIL));
            throw new SubmissionError(validateResponse.data);
        }
        try {
            const searchResponse = await axios.post(urls.search, viewModel);
            dispatch(action(LOCTA_PLUS_V2_PERFORMSEARCH_SUCCESS, searchResponse.data || []));
        }
        catch (err) {
            dispatch(action(LOCTA_PLUS_V2_PERFORMSEARCH_FAIL));
            goToStep(2);
        }
    }
}


export const validateSearch = (model) => {
    return async (dispatch) => {
        const formValues = new LoctaPlusV2SearchViewModel(model);

        dispatch(clearSubmitErrors(formName));

        const response = await axios.post(urls.validate, formValues);
        if (Object.keys(response.data).length)
            throw response.data;
    }
}

export const resetSearch = (values) => {
    return (dispatch) => {
        dispatch(action(LOCTA_PLUS_V2_SEARCH_RESET));
        dispatch(initialize(formName, { ...values, ...baseValues }));
        goToStep(1);
    }
}

// const goToStep = (step) => window.location.hash=`step${step}`;
const goToStep = () => {};