const isNotNullOrEmpty = (value) => {
  return value !== null && value !== "";
}

const isNullOrEmpty = (value) => {
  return value === null || value === "";
}

const validate = (fields, showVotersRollByAddress) => {
  let ninoFormat = new RegExp(/^[a-zA-Z]{2}[0-9]{6}[a-dA-D]{1}$/);

  let v = {
    forename: {
      isValid: fields.forename !== "" || fields.blankForename === true,
      isFormatValid: true,
      showError: false
    },
    surname: {
      isValid: fields.surname !== "",
      isFormatValid: true,
      showError: false
    },
    companyName: {
      isValid: fields.companyName !== "",
      isFormatValid: true,
      showError: false
    },
    dateOfBirth: {
      isValid: isNotNullOrEmpty(fields.dateOfBirth) && fields.dateOfBirth instanceof Date,
      isFormatValid: isNullOrEmpty(fields.dateOfBirth) || fields.dateOfBirth instanceof Date,
      showError: false
    },
    nino: {
      isValid: fields.nino !== "" && ninoFormat.test(fields.nino),
      isFormatValid: fields.nino === "" || ninoFormat.test(fields.nino),
      showError: false
    },
    postcode: {
      isValid: fields.postcode !== "",
      isFormatValid: true,
      showError: false
    },
    location: {
      isValid: fields.location !== "",
      isFormatValid: true,
      showError: false
    },
    neighbouringCouncilId: {
      isValid: fields.neighbouringCouncilId > 0,
      isFormatValid: true,
      showError: false
    },
    searchStart: {
      isValid: isNullOrEmpty(fields.searchStart) || fields.searchStart instanceof Date,
      isFormatValid: isNullOrEmpty(fields.searchStart) || fields.searchStart instanceof Date,
      showError: false
    },
    searchEnd: {
      isValid: isNullOrEmpty(fields.searchEnd) || fields.searchEnd instanceof Date,
      isFormatValid: isNullOrEmpty(fields.searchEnd) || fields.searchEnd instanceof Date,
      showError: false
    },
    searchesRequired: {
      isValid: true,
      isFormatValid: true,
      showError: false
    }
  };

  if (
    (fields.searchBenefits ||
      fields.searchBusinessRates ||
      fields.searchDeceased ||
      fields.searchCouncilTaxAddress ||
      fields.searchCouncilTaxName ||
      fields.searchDirectory ||
      fields.searchVotersRoll ||
      fields.searchVotersRollByAddress) === false
  ) {
    v.searchesRequired.isValid = false;
    v.searchesRequired.showError = true;
  }

  if (fields.searchBenefits) {
    let surnameAndDateOfBirthIsValid =
      v.surname.isValid && v.dateOfBirth.isValid;
    let isValid = v.nino.isValid || surnameAndDateOfBirthIsValid;

    if (isValid === false) {
      v.nino.showError = !v.nino.isValid;

      if (surnameAndDateOfBirthIsValid === false) {
        v.surname.showError = !v.surname.isValid;
        v.dateOfBirth.showError = !v.dateOfBirth.isValid;
      }
    }
  }

  if (fields.searchBusinessRates) {
    let nameOrPostcodeIsValid = v.companyName.isValid || v.postcode.isValid;
    let isValid =
      nameOrPostcodeIsValid && v.searchStart.isValid && v.searchEnd.isValid;

    if (isValid === false) {
      if (nameOrPostcodeIsValid === false) {
        v.companyName.showError = !v.companyName.isValid;
        v.postcode.showError = !v.postcode.isValid;
      }
      v.searchStart.showError = !v.searchStart.isValid;
      v.searchEnd.showError = !v.searchEnd.isValid;
    }
  }

  if (fields.searchDeceased) {
      let searchOneValid =
          v.surname.isValid && v.forename.isValid && v.location.isValid && fields.blankForename === false;
      let searchTwoValid = v.surname.isValid && v.dateOfBirth.isValid;

      if (searchOneValid || searchTwoValid) {
        v.forename.showError = false;
        v.surname.showError = false;
        v.location.showError = false;
        v.dateOfBirth.showError = false;
      } else {
        v.forename.showError = !searchOneValid;
        v.surname.showError = !v.surname.isValid;
        v.location.showError = !searchOneValid;
        v.dateOfBirth.showError = !searchTwoValid;
      }
  }

  if (fields.searchCouncilTaxAddress) {
    let isValid = v.postcode.isValid;

    if (isValid === false) {
      v.postcode.showError = !v.postcode.isValid;
    }
  }

  if (fields.searchDirectory) {
    let isValid = v.surname.isValid && v.location.isValid;

    if (isValid === false) {
      v.surname.showError = !v.surname.isValid;
      v.location.showError = !v.location.isValid;
    }
  }

  if (fields.searchVotersRoll) {
    let isValid = v.forename.isValid && v.surname.isValid;

    if (isValid === false) {
      v.forename.showError = !v.forename.isValid;
      v.surname.showError = !v.surname.isValid;
    }
  }

  if (fields.searchVotersRollByAddress && showVotersRollByAddress) {
    let isValid = v.postcode.isValid;

    if (isValid === false) {
      v.postcode.showError = !v.postcode.isValid;
    }
  }

  if (fields.searchCouncilTaxName) {
    // search start is a required field for ctax name
    v.searchStart.isValid = isNotNullOrEmpty(fields.searchStart) && fields.searchStart instanceof Date;

    let isValid =
      v.surname.isValid &&
      v.forename.isValid &&
      v.searchStart.isValid &&
      v.searchEnd.isValid;

    if (isValid === false) {
      v.surname.showError = !v.surname.isValid;
      v.forename.showError = !v.forename.isValid;
      v.searchStart.showError = !v.searchStart.isValid;
      v.searchEnd.showError = !v.searchEnd.isValid;
    }
  }

  let validationFieldKeys = Object.keys(v);
  validationFieldKeys.forEach(validationFieldKey => {
    let validationField = v[validationFieldKey];
    if (validationField.isFormatValid === false) {
      validationField.showError = !validationField.isFormatValid;
    }
  });

  return v;
};

export default validate;
