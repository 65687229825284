import axios from 'axios';
import { initialize, SubmissionError, clearSubmitErrors } from 'redux-form';
import { selector } from '../redux-reducers/voters-roll-search-reducer';


export const VOTERS_ROLL_SEARCH_STAGING_START = 'VOTERS_ROLL_SEARCH_STAGING_START';
export const VOTERS_ROLL_SEARCH_STAGING_SUCCESS = 'VOTERS_ROLL_SEARCH_STAGING_SUCCESS';
export const VOTERS_ROLL_SEARCH_STAGING_FAIL = 'VOTERS_ROLL_SEARCH_STAGING_FAIL';

export const VOTERS_ROLL_SEARCH_START = 'VOTERS_ROLL_SEARCH_START';
export const VOTERS_ROLL_SEARCH_SUCCESS = 'VOTERS_ROLL_SEARCH_SUCCESS';
export const VOTERS_ROLL_SEARCH_FAIL = 'VOTERS_ROLL_SEARCH_FAIL';

export const VOTERS_ROLL_SEARCH_RESET = 'VOTERS_ROLL_SEARCH_RESET';

export const urls = {
    searchStaging: `/api/search/voters-roll/person-list`,
    searchResults: `/api/search/voters-roll`,
    validate: `/api/search/voters-roll/validate`
}

export const formName = "voters-roll-search";

class VotersRollSearchViewModel {
    constructor(model, logId) {
        this.Forename = model.forename;
        this.Surname = model.surname;
        this.NeighbouringCouncilId = model.neighbouringCouncilId;
        this.IsExact = model.isExact;
        this.SearchArea = model.searchArea;
        this.MaxSearchDistance = model.maxSearchDistance;
        this.SelectedRecordIds = model.selectedRecordIds;

        this.IsLinkedSearch = model.isLinkedSearch;
        this.IsLoctaPlusSearch = model.isLoctaPlusSearch;
        this.IsQuickSearch = model.isQuickSearch;

        if (logId) {
            this.LogId = logId;
        }

        this.__RequestVerificationToken = model.__requestVerificationToken;
    }
}

const action = (type, data = null) => ({
    type, data
});

export const searchStaging = (formValues) => {
    return async (dispatch) => {

        dispatch(action(VOTERS_ROLL_SEARCH_STAGING_START, formValues));

        const viewModel = new VotersRollSearchViewModel(formValues);

        const validateResponse = await axios.post(urls.validate, viewModel);
        if (Object.keys(validateResponse.data).length)
            throw new SubmissionError(validateResponse.data);
        try {
            goToStep(2);
            const searchResponse = await axios.post(urls.searchStaging, viewModel);
            dispatch(action(VOTERS_ROLL_SEARCH_STAGING_SUCCESS, searchResponse.data || []));
        }
        catch (err) {
            console.log(err);
            dispatch(action(VOTERS_ROLL_SEARCH_STAGING_FAIL));
        }
    }
}

export function search(selectedRecordIds) {
    return async (dispatch, getState) => {
        const state = getState();
        const searchedValues = selector.searchedValues(state);
        const logId = selector.logId(state);
        const newFormValues = {...searchedValues, selectedRecordIds};
        dispatch(action(VOTERS_ROLL_SEARCH_START, newFormValues));
        goToStep(3);

        const viewModel = new VotersRollSearchViewModel(newFormValues, logId);

        try {
            const searchResponse = await axios.post(urls.searchResults, viewModel);
            dispatch(action(VOTERS_ROLL_SEARCH_SUCCESS, searchResponse.data || []));
        }
        catch (err) {
            dispatch(action(VOTERS_ROLL_SEARCH_FAIL));
        }
    }
}

export function validateSearch(model) {
    return async (dispatch) => {
        const formValues = new VotersRollSearchViewModel(model);

        dispatch(clearSubmitErrors(formName));

        const response = await axios.post(urls.validate, formValues);
        if (Object.keys(response.data).length)
            throw response.data;
    }
}

export function resetSearch(values) {
    return (dispatch) => {

        dispatch(action(VOTERS_ROLL_SEARCH_RESET));
        dispatch(initialize(formName, {
            ...values,
            forename: '',
            surname: '',
            isExact: false,
            neighbouringCouncilId: 0,
            searchArea: 'National',
            maxSearchDistance: 0
        }));
        goToStep(1);
    }
}

const goToStep = (step) => window.location.hash=`step${step}`;