import React, {useState, useEffect} from 'react';
import CouncilForm from './council-form';
import axios from 'axios';
import {BootstrapSpinner} from '../../bootstrap';
import {useHistory} from 'react-router-dom';

const addApi = `/api/councils/add`;
const AddForm = () => {
    const [initialValues, setInitialValues] = useState(null);
    const history = useHistory();

    useEffect(() => {
        axios.get(addApi).then(res => setInitialValues(res.data));
    }, [])
    
    const onSubmit = async (values) => {
        let response = await axios.post(addApi, values);
        if(response.data.length === 0){
            history.goBack();
        }
        return response.data;
    }

    if(initialValues === null){
        return <BootstrapSpinner loadingMessage='Loading ...'/>
    }

    return <CouncilForm onSubmit={onSubmit} initialValues={initialValues}/>;
};

export default AddForm;