import React from 'react';
import ReactTable from 'react-table-v6';
import {EditCouncilButton, HoldingAreaLink, ArchiveAreaLink} from './buttons';
import {stringAcc, cellAcc, fuzzyFilter} from '../../table';


const columns = [
    stringAcc('Id', d => String(d.id), 'Id'),
    stringAcc('Council', 'name'),
    stringAcc('Code', 'code'),
    stringAcc('CTAX Email', 'councilTaxEmailAddress'),
    stringAcc('Benefits Email', 'benefitsEmailAddress'),
    stringAcc('NNDR Email', 'nndrEmailAddress'),
    stringAcc('IsActive', d => String(d.isActive), 'isActive'),
    cellAcc("Holding Area", d=>d, props => <HoldingAreaLink {...props}/>, 'holdingarea', '', {filterable: false}),
    cellAcc("Archive Area", d=>d, props => <ArchiveAreaLink {...props}/>, 'archivearea', '', {filterable: false}),
    cellAcc("Edit", d => d, props => <EditCouncilButton {...props}/>, 'edit', '', {filterable: false})
    // cellAcc("Delete", d => d, props => <DeleteCouncilButton {...props}/>, 'delete', '', {filterable: false}),
]

// {
//     "Id": 3544,
//     "Name": "Vale of White Horse",
//     "ContactNumber": "01235 123456",
//     "CouncilTaxEmailAddress": "support@magnetsolutions.co.uk",
//     "BenefitsEmailAddress": "support@magnetsolutions.co.uk",
//     "NNDREmailAddress": "support@magnetsolutions.co.uk",
//     "PostCode": "OX14 3JE",
//     "GridN": 197114,
//     "GridE": 449895,
//     "DistanceInMiles": 126,
//     "IsNew": false,
//     "IsActive": true,
//     "DateCreated": "/Date(1144252898357-0000)/",
//     "AllowFileImports": true,
//     "ImmediateImport": false,
//     "FtpLocation": "\\holding_area\\uploads",
//     "LegacyImportLocation": "\\\\10.10.120.40\\dev share\\uploads",
//     "FileArchiveLocation": "\\archive_area\\uploads",
//     "TPHMRCVisible": true,
//     "ShowEmploymentFields": true,
//     "ExtractAlertBENSDATA": true,
//     "ExtractAlertLOCTAPER": true,
//     "ExtractAlertLOCTAPRP": true,
//     "ExtractAlertNNDR": true
// }

const Table = ({councils}) => {
    return (
        <div className="ReactTable">
            <ReactTable
                data={councils}
                columns={columns}
                defaultPageSize={10}
                filterable={true}
                defaultFilterMethod={fuzzyFilter}
            />
        </div>
    );
};

export default Table;