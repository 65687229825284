import React, { Component } from 'react';
import {connect} from 'react-redux';
import * as actions from '../../../redux-actions/superuser-actions';
import {selector} from '../../../redux-reducers/clients-reducer';
import ListItem from './list-item';

class ClientLoginLinks extends Component {

    constructor(props) {
        super(props);

        this.state = { selectedClientId: -1 };
    
        this.onClientChange = this.onClientChange.bind(this);
        this.onAddClick = this.onAddClick.bind(this);
    }

    componentDidMount() {
        this.props.getClients();
    }

    onClientChange(ev) {
        var selectedClientId = ev.target.value;

        this.setState({ ...this.state, selectedClientId});
        this.props.getClientsLoginLinks(selectedClientId);
    }

    onAddClick(ev) {
        ev.preventDefault();

        this.props.addToClientsLoginLinks(this.state.selectedClientId);
    }

    render() {

        const { clients, loginLinks } = this.props;

        let existing = (
            <ul>
                { loginLinks.length > 0 
                    ? loginLinks.map((a) => <ListItem key={a.id} ClientId={a.clientId} Token={a.token} />)
                    : <li>None</li>
                }
            </ul>
        );

        return (
            <div className="bg-grad edit-record">
                <ul className="admin-edit">
                    <li className="row-fluid">
                        <label className="span2">Client</label>
                        <div className="span6">
                            <select className="input-block-level" rows={4} value={this.state.selectedClientId} onChange={this.onClientChange}>
                                <option>-- Please Select --</option>
                                { clients.map((c) => <option value={c.clientId} key={c.clientId}>{c.name}</option> )}
                            </select>
                            <button className="btn" onClick={this.onAddClick}><i className="icon-play"></i> Generate New Link</button>
                        </div>
                    </li>
                    <li className="row-fluid existing-links">
                        <label className="span2">Existing</label>
                        <div className="span6">
                            { existing }
                        </div>
                    </li>
                </ul>
            </div>
        );
    }

    
}

const mapStateToProps = (state) => {
    return {
        clients: selector.clients(state),
        loginLinks: selector.loginLinks(state),
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        getClients: () => {
            dispatch(actions.fetchClients());
        },
        getClientsLoginLinks: (clientId) => {
            dispatch(actions.fetchClientsLoginLinks(clientId));
        },
        addToClientsLoginLinks: (clientId) => {
            dispatch(actions.addToClientsLoginLinks(clientId));
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ClientLoginLinks);