import React from "react";
import tableFn from '../../react-table-extras/table';
import {LoctaButton, PrimaryButton, DangerButton, Row, Col} from '../../bootstrap';
import { Link } from 'react-router-dom';

export const EditCouncilButton = ({id}) => {
    return (
        <Row center>
            <Link
                to={{
                    pathname: `Edit/${id}`
                }}
            >
                <PrimaryButton small>Edit</PrimaryButton>
            </Link>
        </Row>
    );
};

const ManageClient = ({clientId}) => (
    <Row className='d-flex justify-content-between align-items-center w-100'>
        <Col size={5}>
            <EditCouncilButton id={clientId}/>
        </Col>
        <Col size={5}>
            <LoctaButton ButtonFn={DangerButton} textComponent={<>Remove</>} fontSize="9px" fontWeight="normal" onClick={() => window.location=`/Clients/Delete/${clientId}`} />
        </Col>
    </Row>
);

const columns = [
    {
        Header: 'Name',
        accessor: 'name'
    },
    {
        Header: 'Password Policy',
        accessor: 'passwordPolicyDays',
        Cell: ({value}) => <>{value} days</>,
        disableFilters: true
    },
    {
        Header: 'Council Name',
        accessor: 'councilName'
    },
    {
        Header: 'Manage Client',
        accessor: 'clientId',
        Cell: ({value}) => <ManageClient clientId={value}/>,
        disableFilters: true
    }
];
const FilterTable = tableFn(columns);

const Table = ({clients}) => {
    return (
        <FilterTable data={clients}/>
    );
};

export default Table;